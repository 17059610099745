import Vue from 'vue'
import Router from 'vue-router'

// Order Management
const manifest = () => import("@/pages/order/manifest.vue")
const manifestDetail = () => import("@/pages/order/manifestDetail.vue")

// Package
const pkg = () => import('@/pages/order/package.vue')
const pkgDetail = () => import('@/pages/order/packageDetail.vue')
const pkgSearch = () => import('@/pages/order/packageSearch.vue')

// Trailer
const trailerGroundDashboard = () => import('@/pages/order/trailerGroundDashboard.vue')
const trailerAirDashboard = () => import('@/pages/order/trailerAirDashboard.vue')
const trailerCanaryDashboard = () => import('@/pages/order/trailerCanaryDashboard.vue')
const trailer = () => import('@/pages/order/trailer.vue')
const trailerDetail = () => import('@/pages/order/trailerDetail.vue')

// Other
const cacesaPoeUpload = () => import('@/pages/order/cacesaPoeUpload.vue')

// Stock Transfer
const stockTransferDashboard = () => import('@/pages/stockTransfer/dashboard.vue')
const gatewayStatusDashboard = () => import('@/pages/nltr/gatewayStatusDashboard.vue')
const stockTransferOrderDetail = () => import('@/pages/stockTransfer/detail.vue')
const stockTransferExportDeclarationUpload = () => import('@/pages/stockTransfer/exportDeclarationUpload.vue')
const euYcCodeUpload = () => import('@/pages/stockTransfer/euYCCodeUpload.vue')
const stockTransferAssistUpliftUpload = () => import('@/pages/stockTransfer/stockTransferAssistUpliftUpload.vue')

// Billing
const invoiceConfirm = () => import("@/pages/billing/invoiceConfirm.vue")
const invoiceToAmazon = () => import("@/pages/billing/invoiceToAmazon.vue")
const createCreditNoteGTS = () => import("@/pages/billing/createCreditNoteGTS.vue")
const createCreditNoteAGL = () => import("@/pages/billing/createCreditNoteAGL.vue")
const createNLCNInvoice = () => import("@/pages/billing/createNLCNInvoice.vue")

// User Management
const user = () => import('@/pages/user/user.vue')
const role = () => import('@/pages/user/role.vue')

// Data Request
const dataRequestHscode = () => import('@/pages/dataRequest/hsCode.vue')
const dataRequestStatusList = () => import('@/pages/dataRequest/statusList.vue')
const dataRequestClearanceStatus = () => import('@/pages/dataRequest/clearanceStatus.vue')
const dataRequestrepFileLog = () => import('@/pages/dataRequest/grepFileLog.vue')
const dataRequestMissingDocs = () => import('@/pages/dataRequest/missingDocs.vue')
const dataRequestPalletRelation = () => import('@/pages/dataRequest/palletRelation.vue')
const dataRequestEsKyc = () => import('@/pages/dataRequest/esKyc.vue')
const dataInvoiceList = () => import('@/pages/dataRequest/invoiceList.vue')
const dataInternalInvoiceList = () => import('@/pages/dataRequest/internalInvoiceList.vue')
const dataRequestNLTRManifest = () => import("@/pages/dataRequest/nltrManifest")
const dataRequestGTSExportDeclaration = () => import("@/pages/dataRequest/gtsExportDeclaration")
const dataRequestGTSAssistUpliftReport = () => import("@/pages/dataRequest/gtsAssistUpliftReport")
const dataRequestGTSStatus = () => import("@/pages/dataRequest/gtsStatus")
const dataRequestGTSCUSInvoice = () => import("@/pages/dataRequest/gtsCusInvoice")
const dataRequestGTSDTYInvoice = () => import("@/pages/dataRequest/gtsDtyInvoice")

// Turkey-Netherlands
const nltrOperation = () => import('@/pages/nltr/operation.vue')
const tlnlLoadIDDashboard = () => import('@/pages/nltr/dashboardLoadID.vue')
const tlnlMawbDashboard = () => import('@/pages/nltr/dashboardMawb.vue')
const tlnlPreManifest = ()  => import('@/pages/nltr/preManifest.vue')
const tlnlHscodeOverride = () => import('@/pages/nltr/hscodeOverride.vue')
const tlnlTaxAndDutyUpload = () => import('@/pages/nltr/taxAndDutyUpload.vue')
const tlnlPoaReview = () => import('@/pages/nltr/poaReview.vue')
const tlnlStatusWizard = () => import('@/pages/nltr/statusWizard.vue')
const canaryStatusWizard = () => import('@/pages/canary/statusWizard.vue')

// User Center
const login = () => import('@/pages/login/index')
const main = () => import('@/pages/home/main')
const home = () => import('@/pages/home/home')

const ediUpload = () => import('@/pages/edi/upload')
const ediArchive = () => import('@/pages/edi/archive')

const devOpsApi = () => import('@/pages/devops/internal-api.vue')

// TR-POA
const trPoa = () => import('@/pages/public/trPoa')

Vue.use(Router)

const router = new Router({
  defaultRoute: 'home',
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      component: login
    },
    {
      path: '/home/main',
      component: main,
      meta: {authority: '/home'},
      children: [
        {
          path: '/home',
          name: 'home',
          meta: {},
          component: home
        },
        {
          path: '/order/manifest',
          name: 'manifest',
          meta: {permissions: ['PERMISSION_MANIFEST']},
          component: manifest
        },
        {
          path: '/order/manifest/:manifestNumber',
          name: 'manifestDetail',
          meta: {permissions: ['PERMISSION_MANIFEST']},
          props: true,
          component: manifestDetail
        },
        {
          path: "/order/package",
          name: "package",
          meta: {permissions: ['PERMISSION_PACKAGE']},
          component: pkg
        },
        {
          path: "/order/package/:packageTrackingId",
          name: "packageDetail",
          meta: {permissions: ['PERMISSION_PACKAGE']},
          props: true,
          component: pkgDetail
        },
        {
          path: "/order/package-search/:packageTrackingId",
          name: "packageSearch",
          meta: {permissions: ['PERMISSION_PACKAGE']},
          props: true,
          component: pkgSearch
        },
        {
          path: '/order/trailer',
          name: 'trailer',
          meta: {permissions: ['PERMISSION_TRAILER']},
          component: trailer
        },
        {
          path: '/order/trailer/:loadId',
          name: 'trailerDetail',
          meta: {permissions: ['PERMISSION_TRAILER']},
          props: true,
          component: trailerDetail
        },
        {
          path: '/order/cacesa-poe',
          name: 'cacesaPoeUpload',
          meta: {permissions: ['PERMISSION_PACKAGE']},
          component: cacesaPoeUpload
        },
        {
          path: '/order/trailerGroundDashboard',
          name: 'trailerGroundDashboard',
          meta: {permissions: ['PERMISSION_BREXIT_GROUND']},
          component: trailerGroundDashboard
        },
        {
          path: '/order/trailerAirDashboard',
          name: 'trailerAirDashboard',
          meta: {permissions: ['PERMISSION_BREXIT_AIR']},
          component: trailerAirDashboard
        },
        {
          path: '/order/trailerCanaryDashboard',
          name: 'trailerCanaryDashboard',
          meta: {permissions: ['PERMISSION_CANARY']},
          component: trailerCanaryDashboard
        },
        {
          path: '/gatewayStatusDashboard',
          name: 'gatewayStatusDashboard',
          meta: {permissions: ['PERMISSION_NL_TR']},
          component: gatewayStatusDashboard
        },
        {
          path: '/stockTransfer',
          name: 'stockTransferDashboard',
          meta: {permissions: ['PERMISSION_STOCK_TRANSFER']},
          component: stockTransferDashboard
        },
        {
          path: '/stockTransfer/order/:blNumber',
          name: 'stockTransferOrderDetail',
          meta: {permissions: ['PERMISSION_STOCK_TRANSFER']},
          props: true,
          component: stockTransferOrderDetail
        },
        {
          path: '/stockTransfer/export-declaration-upload',
          name: 'stockTransferExportDeclarationUpload',
          meta: {permissions: ['PERMISSION_STOCK_TRANSFER']},
          component: stockTransferExportDeclarationUpload
        },
        {
          path: '/stockTransfer/assist-uplift-upload',
          name: 'stockTransferAssistUpliftUpload',
          meta: {permissions: ['PERMISSION_STOCK_TRANSFER']},
          component: stockTransferAssistUpliftUpload
        },
        {
          path: '/billing/invoice-amz-brexit',
          name: 'invoiceToAmazon',
          meta: {permissions: ['PERMISSION_BILLING_AMAZON_INVOICE']},
          component: invoiceToAmazon
        },
        {
          path: '/billing/invoice-confirm',
          name: 'invoiceConfirm',
          meta: {permissions: ['PERMISSION_BILLING_AMAZON_INVOICE']},
          component: invoiceConfirm
        },
        {
          path: '/billing/credit-note/agl',
          name: 'createCreditNoteAGL',
          meta: {permissions: ['PERMISSION_CREDIT_NOTE_AGL']},
          component: createCreditNoteAGL
        },
        {
          path: '/billing/credit-note/gts',
          name: 'createCreditNoteGTS',
          meta: {permissions: ['PERMISSION_CREDIT_NOTE_GTS']},
          component: createCreditNoteGTS
        },
        {
          path: '/billing/nlcn-invoice',
          name: 'createNLCNInvoice',
          meta: {permissions: ['PERMISSION_BILLING_NLCN_INVOICE']},
          component: createNLCNInvoice
        },
        {
          path: '/data-request/hs-code',
          name: 'dataRequestHsCode',
          meta: {permissions: ['PERMISSION_DATA_REQUEST_HS_CODE']},
          component: dataRequestHscode
        },
        {
          path: '/data-request/status-list',
          name: 'dataRequestStatusList',
          meta: {permissions: ['PERMISSION_DATA_REQUEST_STATUS_LIST']},
          component: dataRequestStatusList
        },
        {
          path: '/data-request/clearance-status',
          name: 'dataRequestClearanceStatus',
          meta: {permissions: ['PERMISSION_DATA_REQUEST_CLEARANCE_STATUS']},
          component: dataRequestClearanceStatus
        },
        {
          path: '/data-request/file-process-log',
          name: 'dataRequestEdiReceiveLog',
          meta: {permissions: ['PERMISSION_DATA_REQUEST_FILE_LOG']},
          component: dataRequestrepFileLog
        },
        {
          path: '/data-request/missing-docs',
          name: 'dataRequestMissingDocs',
          meta: {permissions: ['PERMISSION_DATA_REQUEST_MISSING_DOC']},
          component: dataRequestMissingDocs
        },
        {
          path: '/data-request/pallet-relation',
          name: 'dataRequestPalletRelation',
          meta: {permissions: ['PERMISSION_DATA_REQUEST_PALLET_RELATION']},
          component: dataRequestPalletRelation
        },
        {
          path: '/data-request/es-kyc',
          name: 'dataRequestEsKyc',
          meta: {permissions: ['PERMISSION_DATA_REQUEST_ES_KYC']},
          component: dataRequestEsKyc
        },
        {
          path: '/data-request/nltr-manifest',
          name: 'dataRequestNLTRManifest',
          meta: {permissions: ['PERMISSION_NL_TR']},
          component: dataRequestNLTRManifest
        },
        {
          path: '/data-request/gts-export-declaration',
          name: 'dataRequestGTSExportDeclaration',
          meta: {permissions: ['PERMISSION_DATA_REQUEST_EXPORT_DECLARATION']},
          component: dataRequestGTSExportDeclaration
        },
        {
          path: '/data-request/gts-assist-uplift-report',
          name: 'dataRequestGTSAssistUpliftReport',
          meta: {permissions: ['PERMISSION_DATA_REQUEST_ASSIST_UPLIFT_REPORT']},
          component: dataRequestGTSAssistUpliftReport
        },
        {
          path: '/data-request/gts-status',
          name: 'dataRequestGTSStatus',
          meta: {permissions: ['PERMISSION_STOCK_TRANSFER']},
          component: dataRequestGTSStatus
        },
        {
          path: '/data-request/gts-cus-invoice',
          name: 'dataRequestGTSCUSInvoice',
          meta: {permissions: ['PERMISSION_DATA_REQUEST_GTS_INVOICE']},
          component: dataRequestGTSCUSInvoice
        },
        {
          path: '/data-request/gts-dty-invoice',
          name: 'dataRequestGTSDTYInvoice',
          meta: {permissions: ['PERMISSION_DATA_REQUEST_GTS_INVOICE']},
          component: dataRequestGTSDTYInvoice
        },
        {
          path: '/data-request/invoice-list',
          name: 'dataRequestInvoiceList',
          meta: {permissions: ['PERMISSION_DATA_REQUEST_INVOICE_LIST']},
          component: dataInvoiceList
        },
        {
          path: '/data-request/internal-invoice-list',
          name: 'dataRequestInternalInvoiceList',
          meta: {permissions: ['PERMISSION_BREXIT_GROUND']},
          component: dataInternalInvoiceList
        },
        {
          path: '/nltr/operation/:operation/:direction/:jobNumber',
          name: 'nltrOperation',
          meta: {permissions: ['PERMISSION_NL_TR_OPERATION_ORIGIN', 'PERMISSION_NL_TR_OPERATION_DESTINATION']},
          component: nltrOperation,
          props: true,
        },
        {
          path: '/nltr/loadIDDashboard',
          name: 'tlnlLoadIDDashboard',
          meta: {permissions: ['PERMISSION_NL_TR', 'PERMISSION_NL_TR_OPERATION_ORIGIN', 'PERMISSION_NL_TR_OPERATION_DESTINATION']},
          component: tlnlLoadIDDashboard
        },
        {
          path: '/nltr/mawbDashboard/normal',
          name: 'tlnlMAWBDashboardNormal',
          meta: {permissions: ['PERMISSION_NL_TR', 'PERMISSION_NL_TR_OPERATION_ORIGIN', 'PERMISSION_NL_TR_OPERATION_DESTINATION']},
          props: {mode: 'NORMAL'},
          component: tlnlMawbDashboard
        },
        {
          path: '/nltr/mawbDashboard/return',
          name: 'tlnlMAWBDashboardReturn',
          meta: {permissions: ['PERMISSION_NL_TR', 'PERMISSION_NL_TR_OPERATION_ORIGIN', 'PERMISSION_NL_TR_OPERATION_DESTINATION']},
          props: {mode: 'RETURN'},
          component: tlnlMawbDashboard
        },
        {
          path: '/nltr/preManifest',
          name: 'tlnlPreManifest',
          meta: {permissions: ['PERMISSION_NL_TR_OPERATION_DESTINATION']},
          component: tlnlPreManifest
        },
        {
          path: '/nltr/hscodeOverride',
          name: 'tlnlHscodeOverride',
          meta: {permissions: ['PERMISSION_NL_TR_HSCODE_OVERRIDE']},
          component: tlnlHscodeOverride
        },
        {
          path: '/nltr/taxAndDutyUpload',
          name: 'tlnlTaxAndDutyUpload',
          meta: {permissions: ['PERMISSION_NL_TR_OPERATION_DESTINATION']},
          component: tlnlTaxAndDutyUpload
        },
        {
          path: '/nltr/poaReview',
          name: 'tlnlPOAReview',
          meta: {permissions: ['PERMISSION_NL_TR_OPERATION_DESTINATION']},
          component: tlnlPoaReview
        },
        {
          path: '/nltr/statusWizard',
          name: 'tlnlStatusWizard',
          meta: {permissions: ['PERMISSION_NL_TR_STATUS_WIZARD']},
          component: tlnlStatusWizard
        },
        {
          path: '/canary/statusWizard',
          name: 'canaryStatusWizard',
          meta: {permissions: ['PERMISSION_CANARY_STATUS_WIZARD']},
          component: canaryStatusWizard
        },
        {
          path: '/edi/upload',
          name: 'ediFileUpload',
          meta: {permissions: ['PERMISSION_EDI_UPLOAD']},
          component: ediUpload
        },
        {
          path: '/edi/archive',
          name: 'ediArchive',
          meta: {permissions: ['PERMISSION_BREXIT_MANUAL_ARCHIVE']},
          component: ediArchive
        },
        {
          path: '/eu-y-c-code-upload',
          name: 'euYcCodeUpload',
          meta: {permissions: ['PERMISSION_NL_TR_ADDITIONAL_CODE_UPLOAD']},
          component: euYcCodeUpload
        },
        {
          path: '/devops/api',
          name: 'devOpsApi',
          meta: {permissions: ['PERMISSION_RAW_API']},
          component: devOpsApi
        },
        {
          path: '/user',
          name: 'userManage',
          meta: {permissions: ['PERMISSION_USER_MANAGEMENT']},
          component: user
        },
        {
          path: '/role',
          name: 'roleManage',
          meta: {permissions: ['PERMISSION_USER_MANAGEMENT']},
          component: role
        }
      ]
    },
    {
      path: '/tr/poa',
      name: 'trPoa',
      component: trPoa
    },
    {
      path: '*',
      redirect: '/login'
    }
  ]
})

export default router
